import { EnvService } from '@/modules/@core/services/env-service';
import GhostContentAPI from '@tryghost/content-api';

export function ghostApi() {
  return new GhostContentAPI({
    url: EnvService.getGhostUrl(),
    key: EnvService.getGhostContentApi(),
    version: 'v3.0',
  });
}
