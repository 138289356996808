/** Tailwind v2 doesn't support ESM modules. Vite can handle importing it as ESM module,
 * but the Tailwind Intellisense extension doesn't support it.
 *
 * We can change it to ESM module once we upgrade to version 3.3
 * https://github.com/tailwindlabs/tailwindcss/discussions/5858
 */
module.exports = {
  purge: false,
  theme: {
    extend: {
      spacing: {
        1200: '1200px',
        1300: '1300px',
        1600: '1600px',
        2000: '2000px',
        500: '500px',
        two: '2px',
        5: '1.25rem',
        6: '1.5rem',
        7: '1.75rem',
        9: '2.25rem',
        11: '2.75rem',
        14: '3.5rem',
        28: '7rem',
        36: '9rem',
        44: '11rem',
        52: '13rem',
        60: '15rem',
        72: '18rem',
        80: '20rem',
        96: '24rem',
      },
      screens: {
        sm: '600px',
        md: '960px',
        lg: '1264px',
        xl: '1800px',
      },
      maxWidth: (theme) => ({
        ...theme('spacing'),
      }),
      maxHeight: (theme) => ({
        ...theme('spacing'),
      }),
      minWidth: {
        'fit-content': 'fit-content',
      },
      width: {
        'fit-content': 'fit-content',
      },
      strokeWidth: {
        3: '3px',
        4: '4px',
      },
    },
    colors: {
      'primary-darken-4': 'var(--primary-darken-4)',
      'primary-darken-3': 'var(--primary-darken-3)',
      'primary-darken-2': 'var(--primary-darken-2)',
      'primary-darken-1': 'var(--primary-darken-1)',
      primary: 'var(--primary)',
      'primary-lighten-1': 'var(--primary-lighten-1)',
      'primary-lighten-2': 'var(--primary-lighten-2)',
      'primary-lighten-3': 'var(--primary-lighten-3)',
      'primary-lighten-4': 'var(--primary-lighten-4)',
      'primary-lighten-5': 'var(--primary-lighten-5)',

      'accent-darken-4': 'var(--accent-darken-4)',
      'accent-darken-3': 'var(--accent-darken-3)',
      'accent-darken-2': 'var(--accent-darken-2)',
      'accent-darken-1': 'var(--accent-darken-1)',
      accent: 'var(--accent)',
      'accent-lighten-1': 'var(--accent-lighten-1)',
      'accent-lighten-2': 'var(--accent-lighten-2)',
      'accent-lighten-3': 'var(--accent-lighten-3)',
      'accent-lighten-4': 'var(--accent-lighten-4)',
      'accent-lighten-5': 'var(--accent-lighten-5)',

      'secondary-darken-4': 'var(--secondary-darken-4)',
      'secondary-darken-3': 'var(--secondary-darken-3)',
      'secondary-darken-2': 'var(--secondary-darken-2)',
      'secondary-darken-1': 'var(--secondary-darken-1)',
      secondary: 'var(--secondary)',
      'secondary-lighten-1': 'var(--secondary-lighten-1)',
      'secondary-lighten-2': 'var(--secondary-lighten-2)',
      'secondary-lighten-3': 'var(--secondary-lighten-3)',
      'secondary-lighten-4': 'var(--secondary-lighten-4)',
      'secondary-lighten-5': 'var(--secondary-lighten-5)',

      'grey-darken-4': 'var(--grey-darken-4)',
      'grey-darken-3': 'var(--grey-darken-3)',
      'grey-darken-2': 'var(--grey-darken-2)',
      'grey-darken-1': 'var(--grey-darken-1)',
      grey: 'var(--grey)',
      'grey-lighten-1': 'var(--grey-lighten-1)',
      'grey-lighten-2': 'var(--grey-lighten-2)',
      'grey-lighten-3': 'var(--grey-lighten-3)',
      'grey-lighten-4': 'var(--grey-lighten-4)',
      'grey-lighten-5': 'var(--grey-lighten-5)',

      'success-darken-4': 'var(--success-darken-4)',
      'success-darken-3': 'var(--success-darken-3)',
      'success-darken-2': 'var(--success-darken-2)',
      'success-darken-1': 'var(--success-darken-1)',
      success: 'var(--success)',
      'success-lighten-1': 'var(--success-lighten-1)',
      'success-lighten-2': 'var(--success-lighten-2)',
      'success-lighten-3': 'var(--success-lighten-3)',
      'success-lighten-4': 'var(--success-lighten-4)',
      'success-lighten-5': 'var(--success-lighten-5)',

      'error-darken-4': 'var(--error-darken-4)',
      'error-darken-3': 'var(--error-darken-3)',
      'error-darken-2': 'var(--error-darken-2)',
      'error-darken-1': 'var(--error-darken-1)',
      error: 'var(--error)',
      'error-lighten-1': 'var(--error-lighten-1)',
      'error-lighten-2': 'var(--error-lighten-2)',
      'error-lighten-3': 'var(--error-lighten-3)',
      'error-lighten-4': 'var(--error-lighten-4)',
      'error-lighten-5': 'var(--error-lighten-5)',

      'warning-darken-4': 'var(--warning-darken-4)',
      'warning-darken-3': 'var(--warning-darken-3)',
      'warning-darken-2': 'var(--warning-darken-2)',
      'warning-darken-1': 'var(--warning-darken-1)',
      warning: 'var(--warning)',
      'warning-lighten-1': 'var(--warning-lighten-1)',
      'warning-lighten-2': 'var(--warning-lighten-2)',
      'warning-lighten-3': 'var(--warning-lighten-3)',
      'warning-lighten-4': 'var(--warning-lighten-4)',
      'warning-lighten-5': 'var(--warning-lighten-5)',

      black: 'var(--black)',
      'black-opaque': 'var(--black-opaque)',
      white: 'var(--white)',
      hover: 'var(--hover)',
      active: 'var(--active)',
      transparent: 'transparent',
    },
    borderRadius: {
      none: '0',
      sm: '0.1875rem',
      default: '0.375rem',
      DEFAULT: '0.375rem',
      md: '0.5rem',
      lg: '1rem',
      full: '9999px',
    },
    boxShadow: {
      xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
      sm: '0 1px 4px 2px rgba(0,0,0,0.1)',
      default: '0px 2px 2px rgba(0, 0, 0, 0.12), 0px 2px 10px rgba(0, 0, 0, 0.2)',
      DEFAULT: '0px 2px 2px rgba(0, 0, 0, 0.12), 0px 2px 10px rgba(0, 0, 0, 0.2)',
      md: '0 2px 10px 3px rgba(0,0,0,0.2)',
      lg: '0 3px 12px 5px rgba(0,0,0,0.2)',
      xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
      outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
      subtle: '0px 2px 2px rgba(0, 0, 0, 0.05), 0px 2px 10px rgba(0, 0, 0, 0.13)',
      bottom: '0 1px 2px 2px rgb(0 0 0 / 2%), 0 4px 4px 3px rgb(0 0 0 / 4%)',
      'flat-sm': '8px 8px var(--accent-lighten-4)',
      flat: '12px 12px var(--accent-lighten-4)',
      none: 'none',
    },
    fontFamily: {
      sans: [
        'Roboto',
        'system-ui',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
      serif: ['Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
      mono: ['"Roboto Mono"', 'Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace'],
    },
    fontSize: {
      xxs: 'var(--font-xxs)', // 10px
      xs: 'var(--font-xs)', // 12px
      sm: 'var(--font-sm)', // 14px
      base: 'var(--font-base)', // 16px
      lg: 'var(--font-lg)', // 18px
      xl: 'var(--font-xl)', // 20px
      '2xl': 'var(--font-2xl)', // 24px
      '3xl': 'var(--font-3xl)', // 28px
      '4xl': 'var(--font-4xl)', // 32px
      '5xl': 'var(--font-5xl)', // 36px
      '6xl': 'var(--font-6xl)', // 48px
      '7xl': 'var(--font-7xl)', // 64px
      '8xl': 'var(--font-8xl)', // 80px
    },
    fill: (theme) => ({
      ...theme('colors'),
    }),
    minHeight: (theme) => ({
      ...theme('spacing'),
    }),
    minWidth: (theme) => ({
      ...theme('spacing'),
    }),
    inset: (theme) => ({
      ...theme('spacing'),
    }),
  },
  variants: {
    fill: ['responsive', 'hover'],
    backgroundColor: ['responsive', 'hover', 'focus', 'active'],
    backgroundImage: ['responsive', 'hover'],
    borderColor: ['responsive', 'hover', 'focus'],
  },
  plugins: [],
};
