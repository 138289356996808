<template>
  <div
    class="tw-flex tw-flex-col tw-rounded tw-border-2 tw-text-secondary-darken-2"
    :class="{
      'tw-border-primary-lighten-4': !isEnterprisePlan,
      'tw-border-warning-lighten-2 tw-bg-warning-lighten-5': isEnterprisePlan,
    }"
  >
    <!-- Card top part -->
    <div
      class="tw-flex tw-flex-col tw-border-b tw-rounded-t tw-min-h-72 tw-justify-between tw-relative"
      :class="{
        'tw-border-grey-lighten-3': !isEnterprisePlan,
        'tw-bg-warning-lighten-4 tw-border-warning-lighten-2': isEnterprisePlan,
      }"
    >
      <div class="tw-flex tw-flex-col tw-gap-y-2 tw-p-4 tw-relative tw-z-10">
        <h3 class="tw-font-medium tw-text-base tw-uppercase tw-relative tw-leading-none tw-tracking-wide">
          {{ planTitle }}
          <LocIcon :name="planIconName" class="tw-absolute tw-right-0 tw-bottom-0" :size="48" />
        </h3>
        <div class="tw-text-sm">
          <slot />
        </div>
      </div>

      <!-- Price + period -->
      <div v-if="!isEnterprisePlan" class="tw-flex tw-flex-col tw-gap-y-1 tw-px-4">
        <div class="tw-flex tw-items-end tw-font-medium tw-gap-x-two tw-pb-two">
          <span class="tw-text-4xl tw-leading-none">
            {{ price }}
          </span>
          <span class="tw-text-lg">
            {{ t('TierCard.per_month') }}
          </span>
        </div>

        <div
          class="tw-flex tw-flex-wrap tw-text-secondary tw-items-center tw-text-sm tw-gap-1 tw-min-h-5 tw-leading-none"
        >
          <slot name="description" />
        </div>
      </div>
      <div v-else class="tw-absolute z-0 tw-bottom-0 left-0 tw-w-full tw-max-h-full tw-overflow-hidden">
        <img
          data-test="image"
          src="https://directus9.localazy.com/assets/43b02fd2-c18d-4bfc-bbf4-47880e706329"
          alt="enterprise background"
        />
      </div>

      <!-- Key selector + button -->
      <div class="tw-flex tw-flex-col tw-gap-y-3 tw-p-4">
        <div v-if="!isEnterprisePlan">
          <div v-for="feature in sortedFeatureList" :key="feature.name">
            <TierCardKeySelector
              v-if="feature.priceOptions && feature.priceOptions.length > 1"
              v-model="localValue"
              :price-options="feature.priceOptions"
              :activated-option-id="activatedOptionId"
              full-width
              class="tw-text-base"
            />
          </div>
        </div>
        <LocButton :color="isEnterprisePlan ? 'success' : 'primary'" :href="buttonLink" class="hover:tw-text-white">
          {{ buttonLabel }}
        </LocButton>
      </div>
    </div>

    <div class="tw-flex-1 tw-flex tw-flex-col tw-pt-1">
      <LocTransitionContent
        :disabled="desktop"
        :no-label="desktop"
        label-classes="tw-px-3 tw-py-2 tw-uppercase tw-font-medium tw-text-sm tw-text-secondary"
        chevron-classes="tw-w-4 tw-p-px tw-fill-secondary"
        chevron-on-right
        class="tw-mb-2 md:tw-mb-0"
      >
        <template #label>
          {{ t('TierCard.plan_features') }}
        </template>
        <div v-for="feature in sortedFeatureListWithoutSourceKeys" :key="feature.name">
          <LocTooltip placement="right" :disabled="disableTooltip(feature)">
            <component
              :is="disableTooltip(feature) ? 'div' : 'a'"
              :href="feature.link || null"
              class="tw-flex tw-items-center tw-text-secondary-darken-2 tw-text-base tw-w-full tw-px-3 tw-py-2 tw-transition-colors tw-duration-150"
              :class="{
                'hover:tw-bg-primary-lighten-5 hover:tw-text-primary': !disableTooltip(feature) && !isEnterprisePlan,
                'hover:tw-bg-warning-lighten-4 hover:tw-text-warning-darken-4':
                  !disableTooltip(feature) && isEnterprisePlan,
              }"
            >
              <LocIcon :name="feature.icon" :dir="feature.iconDir" :size="16" :class="`tw-mr-2 ${feature.iconColor}`" />
              <div class="tw-flex-1 tw-pr-1 tw-text-sm">
                {{ resolveFeatureLabel(planName, feature) }}
              </div>
              <LocIcon
                v-if="!feature.isReleased"
                name="timer"
                :size="16"
                class="tw-fill-primary-lighten-3 tw-flex-shrink-0"
              />
              <LocIcon
                v-else-if="feature.isBeta"
                name="early-access"
                :size="16"
                class="tw-fill-primary-lighten-3 tw-flex-shrink-0"
              />
              <LocIcon
                v-else-if="feature.isPopular"
                name="fire"
                :size="16"
                class="tw-fill-primary-lighten-3 tw-flex-shrink-0"
              />
            </component>
            <template #tooltip>
              <div class="tw-hidden sm:tw-block tw-bg-white tw-rounded tw-shadow tw-max-w-xs tw-overflow-hidden tw-p-4">
                <h3 class="tw-text-base tw-text-secondary-darken-2 tw-font-medium tw-mb-1">
                  {{ resolveFeatureLabel(planName, feature) }}
                  <LocEmoji v-if="feature.credits" emoji="🔥" class="tw-ml-px" />
                </h3>
                <div class="tw-text-sm tw-text-secondary tw-leading-normal">
                  {{ feature.description }}
                </div>
                <div
                  v-if="!feature.isReleased"
                  class="tw-flex tw-items-center tw-justify-between tw-pt-3 tw-mt-3 tw-uppercase tw-font-medium tw-text-secondary tw-border-grey-lighten-4 tw-border-t tw-text-sm"
                >
                  {{ t('TierCard.coming_soon') }}
                  <LocIcon name="timer" :size="16" class="tw-fill-secondary tw-ml-2" />
                </div>
                <div
                  v-else-if="feature.isBeta"
                  class="tw-flex tw-items-center tw-justify-between tw-pt-3 tw-mt-3 tw-uppercase tw-font-medium tw-text-secondary tw-border-grey-lighten-4 tw-border-t tw-text-sm"
                >
                  {{ t('TierCard.in_beta') }}
                  <LocIcon name="early-access" :size="16" class="tw-fill-secondary tw-ml-2" />
                </div>
                <div
                  v-else-if="feature.isPopular"
                  class="tw-flex tw-items-center tw-justify-between tw-pt-3 tw-mt-3 tw-uppercase tw-font-medium tw-text-secondary tw-border-grey-lighten-4 tw-border-t tw-text-sm"
                >
                  {{ t('TierCard.popular') }}
                  <LocIcon name="fire" :size="16" class="tw-ml-2" />
                </div>
              </div>
            </template>
          </LocTooltip>
        </div>
      </LocTransitionContent>
      <a
        v-if="!!docsLink"
        class="tw-flex tw-px-3 tw-py-2 tw-text-sm tw-justify-between tw-items-center tw-mt-auto tw-rounded-b"
        :href="docsLink"
        :class="{
          'tw-fill-primary tw-text-primary hover:tw-bg-primary-lighten-5 hover:tw-text-primary': !isEnterprisePlan,
          'tw-fill-warning-darken-4 tw-text-warning-darken-4 hover:tw-bg-warning-lighten-4 hover:tw-text-warning-darken-4':
            isEnterprisePlan,
        }"
      >
        {{ t('TierCard.read_more') }}
        <LocIcon name="arrow-forward" :size="16" class="tw-ml-2 tw-flex-shrink-0" />
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { IconDir } from '@/modules/@core/models/icon-dir';
import { ExpandedPlanFeature } from '@/modules/pricing/models/expanded-plan-feature';
import { PriceOptions } from '@/modules/pricing/models/price-options';
import { defineComponent, PropType } from 'vue';
import LocEmoji from '@/modules/@core/components/LocEmoji/LocEmoji.vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import { PricingPlanName } from '@/modules/pricing/models/pricing-plan-name';
import { PlanFeature } from '@/modules/pricing/models/plan-feature';
import LocTooltip from '@/modules/@core/components/LocTooltip/LocTooltip.vue';
import { resolveFeatureLabel } from '@/modules/pricing/functions/resolve-feature-label';
import LocSelect from '@/modules/@core/components/LocSelect/LocSelect.vue';
import TierCardKeySelector from '@/modules/pricing/components/TierCard/TierCardKeySelector.vue';
import { langService } from '@/modules/translations/const/lang-service';
import LocTransitionContent from '@/modules/@core/components/LocTransitionContent/LocTransitionContent.vue';
import LocButton from '@/modules/@core/components/LocButton/LocButton.vue';

export default defineComponent({
  name: 'TierCard',

  components: {
    LocIcon,
    LocEmoji,
    LocTooltip,
    LocSelect,
    TierCardKeySelector,
    LocButton,
    LocTransitionContent,
  },

  props: {
    id: {
      type: [String, Number],
      default: 0,
    },
    features: {
      type: Array as PropType<PlanFeature[]>,
      default: (): PlanFeature[] => [],
    },
    planName: {
      type: String as PropType<PricingPlanName>,
      required: true,
    },
    planTitle: {
      type: String,
      required: true,
    },
    docsLink: {
      type: String,
      default: null,
    },
    desktop: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object as PropType<PriceOptions>,
      default: undefined,
    },
    activatedOptionId: {
      type: String,
      default: '',
    },
    price: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: '',
    },
    buttonLink: {
      type: String,
      default: '/register',
    },
  },

  computed: {
    localValue: {
      get(): PriceOptions {
        return this.value;
      },
      set(val: PriceOptions) {
        this.$emit('input', val);
      },
    },
    isEnterprisePlan() {
      return this.planName === 'enterprise';
    },
    expandedFeatureList(): ExpandedPlanFeature[] {
      return this.features.map((feature) => {
        const iconData = this.resolveIcon(feature);
        return {
          ...feature,
          icon: iconData.icon,
          iconColor: iconData.color,
          iconDir: iconData.dir,
        };
      });
    },
    sortedFeatureList(): ExpandedPlanFeature[] {
      return [...this.expandedFeatureList].sort((a, b) => a.sort - b.sort);
    },
    sortedFeatureListWithoutSourceKeys(): ExpandedPlanFeature[] {
      return this.sortedFeatureList.filter((feature) => !feature.isSourceKeys);
    },
    planIconName(): string {
      return this.planName.toLowerCase();
    },
  },

  methods: {
    t(key: string) {
      return langService.t(key);
    },

    resolveIcon(feature: PlanFeature): { icon: string; color: string; dir: IconDir } {
      if (feature.amount !== undefined) {
        return { icon: 'arrow-forward', color: 'tw-fill-success', dir: 'left' };
      }
      if (feature.credits) {
        return { icon: 'fire', color: 'tw-fill-warning-darken-1', dir: 'up' };
      }
      if (feature.includesAllInPlan) {
        return { icon: 'done-all', color: 'tw-fill-success', dir: 'up' };
      }
      if (this.isEnterprisePlan) {
        return { icon: 'add', color: 'tw-fill-warning-darken-2', dir: 'up' };
      }
      return { icon: 'add', color: 'tw-fill-primary', dir: 'up' };
    },

    resolveFeatureLabel(planName: string, feature: PlanFeature) {
      return resolveFeatureLabel(planName, feature, this.value);
    },

    disableTooltip(feature: PlanFeature): boolean {
      return !feature.link || !feature.description;
    },
  },
});
</script>
