import { ILanguageToCountry } from '@/modules/@core/models/i-language-to-country';

export const mapLanguageToCountry: ILanguageToCountry = {
  af: 'ZA',
  am: 'ET',
  ar: 'AE',
  cs: 'CZ',
  en: 'GB',
  as: 'IN',
  az: 'AZ',
  be: 'BY',
  bg: 'BG',
  bn: 'BD',
  bs: 'BA',
  ca: 'ES',
  da: 'DK',
  de: 'DE',
  el: 'GR',
  es: 'ES',
  et: 'EE',
  eu: 'ES',
  fa: 'IR',
  fi: 'FI',
  fil: 'PH',
  fr: 'FR',
  ga: 'IE',
  gl: 'ES',
  gu: 'IN',
  he: 'IL',
  hi: 'IN',
  hr: 'HR',
  hu: 'HU',
  hy: 'AM',
  id: 'ID',
  is: 'IS',
  it: 'IT',
  ja: 'JP',
  jam: 'JM',
  ka: 'GE',
  kk: 'KZ',
  km: 'KH',
  kn: 'IN',
  ko: 'KR',
  ky: 'KG',
  lo: 'TH',
  lt: 'LT',
  lv: 'LV',
  mk: 'MK',
  ml: 'IN',
  mn: 'MN',
  mr: 'IN',
  ms: 'MY',
  mt: 'MT',
  my: 'MM',
  nb: 'NO',
  nn: 'NO',
  ne: 'NP',
  nl: 'NL',
  or: 'IN',
  pa: 'IN',
  pl: 'PL',
  pt: 'PT',
  ro: 'RO',
  ru: 'RU',
  si: 'LK',
  sk: 'SK',
  sl: 'SI',
  sq: 'AL',
  sr: 'RS',
  sv: 'SE',
  sw: 'KE',
  ta: 'IN',
  te: 'IN',
  th: 'TH',
  tk: 'TM',
  tr: 'TR',
  uk: 'UA',
  ur: 'PK',
  uz: 'UZ',
  vi: 'VN',
  zu: 'ZA',
  zh: 'CN',
};
