import { EnvService } from '@/modules/@core/services/env-service';
import axios, { AxiosInstance } from 'axios';

/**
 * This service targets protected webhooks server which is uses Ghost's admin API,
 * which means it can access all the data, including articles in draft
 */
export function ghostWebhooksApi(): AxiosInstance {
  return axios.create({
    baseURL: EnvService.getGhostDirectusUrl(),

    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  });
}
