<template>
  <div
    class="tw-bg-white tw-flex tw-flex-wrap tw-w-full tw-border tw-border-accent-lighten-3 tw-py-3 tw-px-3 sm:tw-px-6 tw-rounded tw-relative tw-overflow-hidden"
  >
    <div v-if="showProgress" class="tw-h-1 tw-w-full tw-absolute tw-top-0 tw-left-0">
      <div class="tw-h-full tw-bg-success-lighten-1" :style="`width: ${progress}%`" />
    </div>
    <div class="tw-flex-1 tw-flex tw-items-center">
      <!-- @slot icon area -->
      <slot name="icon">
        <LocIcon class="tw-w-10 tw-h-10 sm:tw-w-12 sm:tw-h-12 tw-mr-2 tw-fill-secondary-lighten-3" name="file" />
      </slot>

      <div class="tw-flex tw-flex-col tw-text-left">
        <LocInput
          v-if="isEditing"
          :value="fileName"
          no-details
          autofocus
          :error="error"
          @input="(value) => $emit('update:fileName', value)"
          @blur="isEditing = false"
        />
        <div v-else class="tw-text-sm sm:tw-text-base tw-font-normal tw-text-secondary-lighten-3 tw-mb-2">
          {{ fileName }}
        </div>
        <div class="tw-flex tw-items-center tw-gap-2 tw-text-secondary-lighten-2 text-sm">
          <span class="tw-border-r tw-border-secondary-lighten-4 tw-pr-2 tw-uppercase">
            {{ fileExtension }}
          </span>
          <span>
            {{ readableFileSize }}
          </span>
        </div>
      </div>
    </div>

    <div class="tw-flex tw-m-auto tw-justify-between">
      <!-- @slot area on the right side of the file area -->
      <slot />
      <div
        v-if="editable"
        class="sm:tw-m-auto tw-cursor-pointer tw-fill-grey-lighten-1 hover:tw-fill-secondary"
        @click="isEditing = true"
      >
        <LocIcon
          class="tw-w-6 tw-h-6 sm:tw-w-8 sm:tw-h-8 sm:tw-ml-4 tw-transition-colors tw-ease-out tw-duration-100"
          name="edit"
        />
      </div>
      <div
        v-if="showDelete"
        class="sm:tw-m-auto tw-cursor-pointer tw-fill-grey-lighten-1 hover:tw-fill-secondary"
        @click="onDelete"
      >
        <LocIcon class="tw-w-5 tw-h-5 sm:tw-ml-4 tw-transition-colors tw-ease-out tw-duration-100" name="clear" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import LocInput from '@/modules/@core/components/LocInput/LocInput.vue';

export default defineComponent({
  name: 'UploadedFile',

  components: {
    LocIcon,
    LocInput,
  },

  props: {
    /**
     * Name of the file
     */
    fileName: {
      type: String,
      required: true,
    },
    /**
     * Size of the file in bytes
     */
    fileSize: {
      type: Number,
      required: true,
    },
    /**
     * Should the file have delete button
     */
    showDelete: {
      type: Boolean,
      default: false,
    },
    /**
     * File name is editable
     */
    editable: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    /**
     * Percentual progress of the file processing
     */
    progress: {
      type: Number,
      default: 100,
    },
    /**
     * Show progress bar
     */
    showProgress: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isEditing: false,
    };
  },

  computed: {
    readableFileSize(): string {
      if (this.fileSize > 1024) {
        return `${Math.floor(this.fileSize / 1024)}KB`;
      }
      return `${this.fileSize}B`;
    },
    fileExtension(): string {
      return this.fileName.split('.').pop() || '';
    },
  },

  methods: {
    onDelete() {
      this.$emit('delete', this.fileName);
    },
  },
});
</script>
