<template>
  <LocSelect
    v-model="localValue"
    no-details
    :items="priceOptions || []"
    :disabled="selectDisabled"
    dropdown-content-classes="tw-text-base tw-z-50 tw-rounded tw-bg-white tw-shadow-subtle tw-font-sans tw-cursor-default tw-overflow-hidden"
  >
    <template #item-content="{ item, active }">
      <div class="tw-flex tw-justify-between tw-w-full tw-items-center">
        <span
          :class="{
            'tw-text-grey': item.disabled,
            'tw-text-primary': active,
            'tw-text-secondary-darken-2': !active,
          }"
        >
          {{ item.label }}
        </span>
        <LocChip
          v-if="activatedOptionId === item.priceId"
          class="tw-bg-success-lighten-4 tw-border-success-lighten-4"
          color="success"
          borderless
          tiny
        >
          <span class="tw-uppercase tw-text-success-darken-1 tw-font-medium tw-text-xs">
            {{ t('TierCardKeySelector.active') }}
          </span>
        </LocChip>
      </div>
    </template>
  </LocSelect>
</template>

<script lang="ts">
import { PriceOptions } from '@/modules/pricing/models/price-options';
import { defineComponent, PropType } from 'vue';
import LocSelect from '@/modules/@core/components/LocSelect/LocSelect.vue';
import { langService } from '@/modules/translations/const/lang-service';
import LocChip from '@/modules/@core/components/LocChip/LocChip.vue';

export default defineComponent({
  name: 'TierCardKeySelector',

  components: {
    LocSelect,
    LocChip,
  },

  props: {
    priceOptions: {
      type: Array as PropType<PriceOptions[]>,
      required: true,
    },
    value: {
      type: Object as PropType<PriceOptions>,
      default: undefined,
    },
    activatedOptionId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectedIndex: 0,
    };
  },

  computed: {
    localValue: {
      get(): PriceOptions {
        return this.value || this.priceOptions[this.selectedIndex];
      },
      set(val: PriceOptions) {
        this.setSelectedIndex(val);
        this.$emit('input', val);
      },
    },
    selectDisabled(): boolean {
      return this.priceOptions.every((option: PriceOptions) => option.disabled);
    },
  },

  watch: {
    value() {
      if (typeof this.value !== 'undefined') {
        this.localValue = this.value;
      }
    },

    priceOptions() {
      this.$emit('input', this.priceOptions[this.selectedIndex]);
    },
  },

  created() {
    this.setSelectedIndex(this.value);
    this.$emit('input', this.localValue);
  },

  methods: {
    t(key: string) {
      return langService.t(key);
    },

    setSelectedIndex(val: PriceOptions) {
      const index = this.priceOptions.findIndex((option: PriceOptions) => option === val);
      this.selectedIndex = index > -1 ? index : 0;
    },
  },
});
</script>
