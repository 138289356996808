import { PluralizationRules, PluralizationRuleResolver } from '../models/pluralization-rule';

const oneOther: PluralizationRuleResolver = (choice: number, choicesLength: number) => {
  choice = Math.abs(choice);

  if (choicesLength === 2) {
    return choice ? (choice > 1 ? 1 : 0) : 1;
  }

  return choice ? Math.min(choice, 2) : 0;
};

const oneFewOther: PluralizationRuleResolver = (choice: number, choicesLength: number) => {
  /** We work with '0' option */
  if (choicesLength === 4) {
    if (choice === 0) {
      return 0;
    }

    if (choice === 1) {
      return 1;
    }

    if (choice >= 2 && choice <= 4) {
      return 2;
    }

    return 3;
  }

  if (choice <= 1) {
    return 0;
  }

  if (choice >= 2 && choice <= 4) {
    return 1;
  }

  return 2;
};

export const pluralizationRules: PluralizationRules = {
  cs: oneFewOther,
  en: oneOther,
  es: oneOther,
  de: oneOther,
  xxa: oneOther,
  fallback: oneOther,
};
