import { FuzzySearchOptions } from '@/modules/@core/models/loc-autocomplete';

export const defaultFuzzySearchOptions: FuzzySearchOptions = {
  enabled: false,
  minScore: 0.5,
  includeScore: true,
  minMatchCharLength: 2,
  threshold: 0.2,
  includeMatches: true,
  findAllMatches: false,
  ignoreLocation: true,
  shouldSort: true,
  keys: [
    {
      name: 'value',
      weight: 0.7,
    },
    {
      name: 'label',
      weight: 0.3,
    },
  ],
};
