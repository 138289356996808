import { debounce } from 'lodash-es';
import resolveConfig from 'tailwindcss/resolveConfig.js';
import Vue from 'vue';

import tailwindBase from '../../../../tailwind.config.base.cjs';

// Read screens from tailwind config
const tailwindConfig = resolveConfig(tailwindBase);

// @ts-expect-error types
const getScreenWidth = (name: string) => parseFloat(tailwindConfig.theme.screens[name]);
const screens = {
  sm: getScreenWidth('sm'),
  md: getScreenWidth('md'),
  lg: getScreenWidth('lg'),
  xl: getScreenWidth('xl'),
};

const mobileBreakpoint = 1264;

const getClientWidth = () => {
  if (typeof document === 'undefined') return 0;
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
};

const getClientHeight = () => {
  if (typeof document === 'undefined') return 0;
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
};

const xs = (val: number) => val < screens.sm;
const sm = (val: number) => val >= screens.sm;
const md = (val: number) => val >= screens.md;
const lg = (val: number) => val >= screens.lg;
const xl = (val: number) => val >= screens.xl;

const getBreakpointName = (w: number) => {
  if (xl(w)) return 'xl';
  if (lg(w)) return 'lg';
  if (md(w)) return 'md';
  if (sm(w)) return 'sm';
  return 'xs';
};

const xsOnly = (clientWidth: number) => getBreakpointName(clientWidth) === 'xs';
const smOnly = (clientWidth: number) => getBreakpointName(clientWidth) === 'sm';
const smAndDown = (clientWidth: number) => (xs(clientWidth) || sm(clientWidth)) && !md(clientWidth);
const smAndUp = (clientWidth: number) => sm(clientWidth);
const mdOnly = (clientWidth: number) => getBreakpointName(clientWidth) === 'md';
const mdAndDown = (clientWidth: number) => (xs(clientWidth) || sm(clientWidth) || md(clientWidth)) && !lg(clientWidth);
const mdAndUp = (clientWidth: number) => md(clientWidth);
const lgOnly = (clientWidth: number) => getBreakpointName(clientWidth) === 'lg';
const lgAndDown = (clientWidth: number) =>
  (xs(clientWidth) || sm(clientWidth) || md(clientWidth) || lg(clientWidth)) && !xl(clientWidth);
const lgAndUp = (clientWidth: number) => lg(clientWidth);
const xlOnly = (clientWidth: number) => getBreakpointName(clientWidth) === 'xl';
const height = (ssr = false) => (ssr ? 0 : getClientHeight());
const width = (ssr = false) => (ssr ? 0 : getClientWidth());

const mobile = (val: number) => {
  if (typeof mobileBreakpoint === 'number') {
    return width() < mobileBreakpoint;
  }

  const breakpoints = {
    xs: 0,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
  } as const;

  const current = breakpoints[getBreakpointName(val)];
  const max = breakpoints[mobileBreakpoint];

  return current <= max;
};

export const breakpoints = Vue.observable({
  xs: xs(getClientWidth()),
  sm: sm(getClientWidth()),
  md: md(getClientWidth()),
  lg: lg(getClientWidth()),
  xl: xl(getClientWidth()),
  is: getBreakpointName(getClientWidth()),

  xsOnly: xsOnly(getClientWidth()),
  smOnly: smOnly(getClientWidth()),
  smAndDown: smAndDown(getClientWidth()),
  /** alias for sm */
  smAndUp: smAndUp(getClientWidth()),
  mdOnly: mdOnly(getClientWidth()),
  mdAndDown: mdAndDown(getClientWidth()),
  /** alias for md */
  mdAndUp: mdAndUp(getClientWidth()),
  lgOnly: lgOnly(getClientWidth()),
  lgAndDown: lgAndDown(getClientWidth()),
  /** alias for lg */
  lgAndUp: lgAndUp(getClientWidth()),
  xlOnly: xlOnly(getClientWidth()),

  height: height(),
  width: width(),

  mobile: mobile(getClientWidth()),
});

const debounceBreakpoints = debounce(() => {
  breakpoints.sm = sm(getClientWidth());
  breakpoints.md = md(getClientWidth());
  breakpoints.lg = lg(getClientWidth());
  breakpoints.xl = xl(getClientWidth());
  breakpoints.is = getBreakpointName(getClientWidth());

  breakpoints.xsOnly = xsOnly(getClientWidth());
  breakpoints.smOnly = smOnly(getClientWidth());
  breakpoints.smAndDown = smAndDown(getClientWidth());
  breakpoints.smAndUp = smAndUp(getClientWidth());
  breakpoints.mdOnly = mdOnly(getClientWidth());
  breakpoints.mdAndDown = mdAndDown(getClientWidth());
  breakpoints.mdAndUp = mdAndUp(getClientWidth());
  breakpoints.lgOnly = lgOnly(getClientWidth());
  breakpoints.lgAndDown = lgAndDown(getClientWidth());
  breakpoints.lgAndUp = lgAndUp(getClientWidth());
  breakpoints.xlOnly = xlOnly(getClientWidth());

  breakpoints.height = height();
  breakpoints.width = width();

  breakpoints.mobile = mobile(getClientWidth());
}, 200);

if (typeof window !== 'undefined') {
  window.addEventListener('resize', debounceBreakpoints, false);
}

declare module 'vue/types/vue' {
  // Global properties can be declared
  // on the `VueConstructor` interface
  interface VueConstructor {
    $breakpoints: typeof breakpoints;
  }
  interface Vue {
    $breakpoints: typeof breakpoints;
  }
}
