<template>
  <div class="tw-flex tw-flex-col tw-overflow-hidden">
    <div
      class="tw-px-4 tw-pt-3 tw-pb-4 tw-flex tw-flex-col tw-border-b tw-border-grey-lighten-3"
      :class="{
        'tw-bg-grey-lighten-5': isInactivable || isCurrent,
        'tw-bg-primary-lighten-5': isActivable,
        'tw-bg-warning-lighten-4': isEnterprisePlan && isActivable,
      }"
    >
      <div class="tw-relative">
        <div
          v-if="isEnterprisePlan"
          class="tw-absolute tw-z-0 tw-bottom-0 left-0 tw-w-full tw-max-h-full tw-overflow-hidden"
        >
          <img
            src="https://directus9.localazy.com/assets/70efc928-421e-468c-b932-3e9dd2bfa1f9"
            alt="enterprise background no lady"
          />
        </div>
        <div class="tw-relative tw-z-10">
          <div class="tw-flex tw-items-center tw-gap-x-1 tw-text-secondary-darken-2">
            <LocIcon :name="planIconName" class="tw-flex tw-flex-shrink-0 tw-mr-two" :size="16" />
            <div class="tw-flex-1 tw-uppercase tw-font-medium tw-text-sm tw-tracking-wide">
              {{ planTitle }}
            </div>
            <a
              v-if="!!docsLink"
              class="tw-flex tw-p-two tw-rounded"
              :href="docsLink"
              target="_blank"
              :class="{
                'tw-fill-primary-lighten-3 hover:tw-bg-primary-lighten-4 hover:tw-fill-primary': !isEnterprisePlan,
                'tw-fill-warning hover:tw-bg-warning-lighten-3 hover:fill-warning-darken-3': isEnterprisePlan,
              }"
            >
              <LocIcon name="open-in-new" :size="16" />
            </a>
          </div>
          <slot name="tier-title-below" />
        </div>
      </div>
      <div v-for="feature in sortedFeatureList" :key="feature.name">
        <TierCardKeySelector
          v-if="feature.priceOptions && feature.priceOptions.length > 1"
          v-model="localValue"
          :price-options="feature.priceOptions"
          :activated-option-id="activatedOptionId"
          class="tw-text-base tw-my-2"
        />
        <div
          v-else-if="feature.isSourceKeys"
          class="tw-my-2 tw-h-10 tw-flex tw-justify-between tw-items-center tw-text-base tw-rounded tw-px-3 tw-border tw-border-grey-lighten-2 tw-bg-grey-lighten-4 tw-pointer-events-none tw-text-grey tw-fill-grey"
        >
          {{ resolveFeatureLabel(planName, feature) }}
          <LocIcon name="chevron-right" dir="right" class="tw-ml-2" :size="12" />
        </div>
      </div>
      <LocButton
        :color="isEnterprisePlan ? 'success' : 'primary'"
        :disabled="disabled || ((isInactivable || isCurrent) && !isReactivable)"
        :secondary="!isReactivable && !isEnterprisePlan"
        @click="onButtonClick"
      >
        <slot name="button" />
      </LocButton>
    </div>

    <div class="tw-flex-1 tw-flex tw-flex-col tw-py-1" :class="{ 'tw-bg-warning-lighten-5': isEnterprisePlan }">
      <div v-for="feature in sortedFeatureListWithoutSourceKeys" :key="feature.name">
        <LocTooltip placement="right" :disabled="disableTooltip(feature)">
          <component
            :is="disableTooltip(feature) ? 'div' : 'a'"
            :href="feature.link || null"
            class="tw-flex tw-items-center tw-text-secondary-darken-2 tw-text-sm tw-w-full tw-px-3 tw-py-2 tw-transition-colors tw-duration-150"
            :class="{
              'hover:tw-bg-primary-lighten-5': !disableTooltip(feature) && !isEnterprisePlan,
              'hover:tw-bg-warning-lighten-4': !disableTooltip(feature) && isEnterprisePlan,
            }"
          >
            <LocIcon :name="feature.icon" :dir="feature.iconDir" :size="16" :class="`tw-mr-2 ${feature.iconColor}`" />
            <div class="tw-flex-1 tw-pr-2">
              {{ resolveFeatureLabel(planName, feature) }}
            </div>
            <LocIcon v-if="!feature.isReleased" name="timer" :size="16" class="tw-fill-primary-lighten-3" />
            <LocIcon v-else-if="feature.isBeta" name="early-access" :size="16" class="tw-fill-primary-lighten-3" />
            <LocIcon v-else-if="feature.isPopular" name="fire" :size="16" class="tw-fill-primary-lighten-3" />
          </component>
          <template #tooltip>
            <div class="tw-hidden sm:tw-block tw-bg-white tw-rounded tw-shadow tw-max-w-xs tw-overflow-hidden tw-p-4">
              <h3 class="tw-text-base tw-text-secondary-darken-2 tw-font-medium tw-mb-1">
                {{ resolveFeatureLabel(planName, feature) }}
                <LocEmoji v-if="feature.credits" emoji="🔥" class="tw-ml-px" />
              </h3>
              <div class="tw-text-sm tw-text-secondary tw-leading-normal">
                {{ feature.description }}
              </div>
              <div
                v-if="!feature.isReleased"
                class="tw-flex tw-items-center tw-justify-between tw-pt-3 tw-mt-3 tw-uppercase tw-font-medium tw-text-secondary tw-border-grey-lighten-4 tw-border-t tw-text-sm"
              >
                {{ t('TierCard.coming_soon') }}
                <LocIcon name="timer" :size="16" class="tw-fill-secondary tw-ml-2" />
              </div>
              <div
                v-else-if="feature.isBeta"
                class="tw-flex tw-items-center tw-justify-between tw-pt-3 tw-mt-3 tw-uppercase tw-font-medium tw-text-secondary tw-border-grey-lighten-4 tw-border-t tw-text-sm"
              >
                {{ t('TierCard.in_beta') }}
                <LocIcon name="early-access" :size="16" class="tw-fill-secondary tw-ml-2" />
              </div>
              <div
                v-else-if="feature.isPopular"
                class="tw-flex tw-items-center tw-justify-between tw-pt-3 tw-mt-3 tw-uppercase tw-font-medium tw-text-secondary tw-border-grey-lighten-4 tw-border-t tw-text-sm"
              >
                {{ t('TierCard.popular') }}
                <LocIcon name="fire" :size="16" class="tw-ml-2" />
              </div>
            </div>
          </template>
        </LocTooltip>
      </div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { IconDir } from '@/modules/@core/models/icon-dir';
import { ExpandedPlanFeature } from '@/modules/pricing/models/expanded-plan-feature';
import { PriceOptions } from '@/modules/pricing/models/price-options';
import { defineComponent, PropType } from 'vue';
import LocEmoji from '@/modules/@core/components/LocEmoji/LocEmoji.vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import LocButton from '@/modules/@core/components/LocButton/LocButton.vue';
import LocTooltip from '@/modules/@core/components/LocTooltip/LocTooltip.vue';
import TierCardKeySelector from '@/modules/pricing/components/TierCard/TierCardKeySelector.vue';
import { PricingPlanName } from '@/modules/pricing/models/pricing-plan-name';
import { TierStatus } from '@/modules/pricing/models/tier-status';
import { PlanFeature } from '@/modules/pricing/models/plan-feature';
import { resolveFeatureLabel } from '@/modules/pricing/functions/resolve-feature-label';
import { langService } from '@/modules/translations/const/lang-service';

export default defineComponent({
  name: 'TierCardUpsell',

  components: {
    LocIcon,
    LocEmoji,
    LocTooltip,
    LocButton,
    TierCardKeySelector,
  },

  props: {
    id: {
      type: [String, Number],
      default: 0,
    },
    status: {
      type: String as PropType<TierStatus>,
      required: true,
    },
    features: {
      type: Array as PropType<PlanFeature[]>,
      default: () => [],
    },
    planName: {
      type: String as PropType<PricingPlanName>,
      required: true,
    },
    planTitle: {
      type: String,
      required: true,
    },
    docsLink: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object as PropType<PriceOptions>,
      default: undefined,
    },
    activatedOptionId: {
      type: String,
      default: '',
    },
  },

  computed: {
    localValue: {
      get(): PriceOptions {
        return this.value;
      },
      set(val: PriceOptions) {
        this.$emit('input', val);
      },
    },
    isEnterprisePlan() {
      return this.planName === 'enterprise';
    },
    isActivable(): boolean {
      return this.status === 'activable';
    },
    isInactivable(): boolean {
      return this.status === 'inactivable';
    },
    isCancelable(): boolean {
      return this.status === 'cancelable';
    },
    isReactivable(): boolean {
      return this.status === 'reactivable';
    },
    isCurrent(): boolean {
      return this.isCancelable || this.isReactivable;
    },
    expandedFeatureList(): ExpandedPlanFeature[] {
      return this.features.map((feature) => {
        const iconData = this.resolveIcon(feature);
        return {
          ...feature,
          icon: iconData.icon,
          iconColor: iconData.color,
          iconDir: iconData.dir,
        };
      });
    },
    sortedFeatureList(): ExpandedPlanFeature[] {
      return [...this.expandedFeatureList].sort((a, b) => a.sort - b.sort);
    },
    sortedFeatureListWithoutSourceKeys(): ExpandedPlanFeature[] {
      return this.sortedFeatureList.filter((feature) => !feature.isSourceKeys);
    },
    planIconName(): string {
      return this.planName.toLowerCase();
    },
  },

  methods: {
    t(key: string) {
      return langService.t(key);
    },

    onButtonClick() {
      if (this.status === 'activable') {
        this.$emit('activate', this.id);
      } else if (this.status === 'cancelable') {
        this.$emit('cancel', this.id);
      } else if (this.status === 'reactivable') {
        this.$emit('reactivate', this.id);
      }
    },

    resolveIcon(feature: PlanFeature): { icon: string; color: string; dir: IconDir } {
      if (feature.amount !== undefined) {
        return { icon: 'arrow-forward', color: 'tw-fill-success', dir: 'left' };
      }
      if (feature.credits) {
        return { icon: 'fire', color: 'tw-fill-warning-darken-1', dir: 'up' };
      }
      if (feature.includesAllInPlan) {
        return { icon: 'done-all', color: 'tw-fill-success', dir: 'up' };
      }
      return { icon: 'add', color: 'tw-fill-primary', dir: 'up' };
    },

    resolveFeatureLabel(planName: string, feature: PlanFeature) {
      return resolveFeatureLabel(planName, feature, this.value);
    },

    disableTooltip(feature: PlanFeature): boolean {
      return !feature.link || !feature.description;
    },
  },
});
</script>
